import {ButtonPriority} from 'wix-ui-tpa';
import {Button} from 'wix-ui-tpa/cssVars';
import React from 'react';
import {useControllerProps} from '../../../../../domain/controllers/ControllerContext';
import {classes} from './CartButton.st.css';
import {settingsParams} from '../../../settingsParams';
import {useSettings} from '@wix/tpa-settings/react';
import classNames from 'classnames';

export enum CartButtonDataHooks {
  root = 'CartButtonDataHooks.root',
}

export interface CartButtonProps {
  className?: string;
}

export const CartButton = ({className}: CartButtonProps) => {
  const {get: getSettings} = useSettings();
  const {navigateToCart} = useControllerProps().navigationStore;

  return (
    <Button
      className={classNames(classes.button, className)}
      data-hook={CartButtonDataHooks.root}
      priority={ButtonPriority.basicSecondary}
      onClick={() => navigateToCart()}
      upgrade
      fullWidth>
      {getSettings(settingsParams.SIDE_CART_CART_BUTTON) as string}
    </Button>
  );
};
