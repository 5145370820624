import {createStylesParams, StyleParamType, wixColorParam, wixFontParam} from '@wix/tpa-settings';
import type {CustomCssVarsFn} from '@wix/yoshi-flow-editor';

type IStylesParams = {
  CART_TITLE_VISIBILITY: StyleParamType.Boolean;
  CART_NUMBER_OF_CART_ITEMS_VISIBILITY: StyleParamType.Boolean;
  CART_ITEM_QUANTITY_COUNTER_VISIBILITY: StyleParamType.Boolean;
  CART_ITEM_IMAGE_VISIBILITY: StyleParamType.Boolean;
  CART_ITEM_INFO: StyleParamType.Boolean;
  CART_SUMMARY_DISCLAIMER_VISIBILITY: StyleParamType.Boolean;
  CART_SUMMARY_SECURE_BADGE_VISIBILITY: StyleParamType.Boolean;
  CART_ACTION_BUTTONS_CHECKOUT_VISIBILITY: StyleParamType.Boolean;
  CART_ACTION_BUTTONS_GO_TO_CART_VISIBILITY: StyleParamType.Boolean;
  cart_showExpressCheckout: StyleParamType.Boolean;
  cart_textFontStyle: StyleParamType.Font;
  cartItemsBackgroundColor: StyleParamType.Color;
  cartItemsDividerColor: StyleParamType.Color;
  cartItemsDividerWidth: StyleParamType.Number;
  cartItemsDividerXPadding: StyleParamType.Number;
  cartItemsDeleteItemButtonColor: StyleParamType.Color;
  cartItemNameFont: StyleParamType.Font;
  cartItemNameColor: StyleParamType.Color;
  cartItemDiscountNameFont: StyleParamType.Font;
  cartItemDiscountNameColor: StyleParamType.Color;
  cartItemMoreDetailsFont: StyleParamType.Font;
  cartItemMoreDetailsColor: StyleParamType.Color;
  cartHeaderBackgroundColor: StyleParamType.Color;
  cartHeaderDividerColor: StyleParamType.Color;
  cartHeaderDividerWidth: StyleParamType.Number;
  cartHeaderDividerPadding: StyleParamType.Number;
  cartTitleFont: StyleParamType.Font;
  cartTitleColor: StyleParamType.Color;
  cartSubtitleFont: StyleParamType.Font;
  cartSubtitleColor: StyleParamType.Color;
  cartCloseButtonColor: StyleParamType.Color;
  cartCloseButtonSize: StyleParamType.Number;
  cartItemPriceFont: StyleParamType.Font;
  cartItemPriceColor: StyleParamType.Color;
  cartItemStrikethroughPriceColor: StyleParamType.Color;
  cartItemTotalPriceFont: StyleParamType.Font;
  cartItemTotalPriceColor: StyleParamType.Color;
  cartItemQuantityBackgroundColor: StyleParamType.Color;
  cartItemQuantityBorderColor: StyleParamType.Color;
  cartItemQuantityBorderWidth: StyleParamType.Number;
  cartItemQuantityBorderRadius: StyleParamType.Number;
  cartItemQuantityFont: StyleParamType.Font;
  cartItemQuantityFontColor: StyleParamType.Color;
  cartSummaryBoxBackgroundColor: StyleParamType.Color;
  cartSummaryBoxDividerColor: StyleParamType.Color;
  cartSummaryBoxDividerWidth: StyleParamType.Number;
  cartSummaryBoxDividerXPadding: StyleParamType.Number;
  cartSummaryBoxSubtotalColor: StyleParamType.Color;
  cartSummaryBoxSubtotalFont: StyleParamType.Font;
  cartSummaryBoxDisclaimerColor: StyleParamType.Color;
  cartSummaryBoxDisclaimerFont: StyleParamType.Font;
  cartSummaryBoxBadgeColor: StyleParamType.Color;
  goToCartButtonBackgroundColor: StyleParamType.Color;
  goToCartButtonBorderColor: StyleParamType.Color;
  goToCartButtonBorderWidth: StyleParamType.Number;
  goToCartButtonCornerRadius: StyleParamType.Number;
  goToCartButtonFont: StyleParamType.Font;
  goToCartButtonFontColor: StyleParamType.Color;
  goToCartButtonBackgroundHoverColor: StyleParamType.Color;
  goToCartButtonBorderHoverColor: StyleParamType.Color;
  goToCartButtonTextHoverColor: StyleParamType.Color;
  goToCheckoutButtonBackgroundColor: StyleParamType.Color;
  goToCheckoutButtonBorderColor: StyleParamType.Color;
  goToCheckoutButtonBorderWidth: StyleParamType.Number;
  goToCheckoutButtonCornerRadius: StyleParamType.Number;
  goToCheckoutButtonFont: StyleParamType.Font;
  goToCheckoutButtonFontColor: StyleParamType.Color;
  goToCheckoutButtonBackgroundHoverColor: StyleParamType.Color;
  goToCheckoutButtonBorderHoverColor: StyleParamType.Color;
  goToCheckoutButtonTextHoverColor: StyleParamType.Color;
  cart_textColor: StyleParamType.Color;
  cart_discountNameColor: StyleParamType.Color;
  cart_titlesColor: StyleParamType.Color;
};

export default createStylesParams<IStylesParams>({
  CART_TITLE_VISIBILITY: {
    key: 'CART_TITLE_VISIBILITY',
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  CART_NUMBER_OF_CART_ITEMS_VISIBILITY: {
    key: 'CART_NUMBER_OF_CART_ITEMS_VISIBILITY',
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  CART_ITEM_QUANTITY_COUNTER_VISIBILITY: {
    key: 'CART_ITEM_QUANTITY_COUNTER_VISIBILITY',
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  CART_ITEM_IMAGE_VISIBILITY: {
    key: 'CART_ITEM_IMAGE_VISIBILITY',
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  CART_ITEM_INFO: {
    key: 'CART_ITEM_INFO',
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  CART_SUMMARY_DISCLAIMER_VISIBILITY: {
    key: 'CART_SUMMARY_DISCLAIMER_VISIBILITY',
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  CART_SUMMARY_SECURE_BADGE_VISIBILITY: {
    key: 'CART_SUMMARY_SECURE_BADGE_VISIBILITY',
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  CART_ACTION_BUTTONS_CHECKOUT_VISIBILITY: {
    key: 'CART_ACTION_BUTTONS_CHECKOUT_VISIBILITY',
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  CART_ACTION_BUTTONS_GO_TO_CART_VISIBILITY: {
    key: 'CART_ACTION_BUTTONS_GO_TO_CART_VISIBILITY',
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  cart_showExpressCheckout: {
    key: 'cart_showExpressCheckout',
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  cart_textFontStyle: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  cartItemsBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  cartItemsDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  cartItemsDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => Number(1),
  },
  cartItemsDividerXPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => Number(20),
  },
  cartItemsDeleteItemButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  cartItemNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  cartItemNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  cartItemDiscountNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  cartItemDiscountNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  cartItemMoreDetailsFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  cartItemMoreDetailsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  cartHeaderBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  cartHeaderDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  cartHeaderDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => Number(1),
  },
  cartHeaderDividerPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => Number(20),
  },
  cartTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', {
      size: 20,
    }),
  },
  cartTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  cartSubtitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  cartSubtitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  cartCloseButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  cartCloseButtonSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => Number(24),
  },
  cartItemPriceFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  cartItemPriceColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  cartItemStrikethroughPriceColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4', 1),
  },
  cartItemTotalPriceFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  cartItemTotalPriceColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  cartItemQuantityBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  cartItemQuantityBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  cartItemQuantityBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => Number(1),
  },
  cartItemQuantityBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => Number(0),
  },
  cartItemQuantityFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  cartItemQuantityFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  cartSummaryBoxBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  cartSummaryBoxDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  cartSummaryBoxDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => Number(1),
  },
  cartSummaryBoxDividerXPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => Number(20),
  },
  cartSummaryBoxSubtotalFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
    }),
  },
  cartSummaryBoxSubtotalColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  cartSummaryBoxDisclaimerFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  cartSummaryBoxDisclaimerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4', 1),
  },
  cartSummaryBoxBadgeColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  goToCartButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  },
  goToCartButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  goToCartButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => Number(1),
  },
  goToCartButtonCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => Number(0),
  },
  goToCartButtonFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  goToCartButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  goToCartButtonBackgroundHoverColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  },
  goToCartButtonBorderHoverColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  goToCartButtonTextHoverColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  goToCheckoutButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  goToCheckoutButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  goToCheckoutButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => Number(0),
  },
  goToCheckoutButtonCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => Number(0),
  },
  goToCheckoutButtonFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  goToCheckoutButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  goToCheckoutButtonBackgroundHoverColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  goToCheckoutButtonBorderHoverColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0),
  },
  goToCheckoutButtonTextHoverColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  cart_textColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  cart_discountNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  cart_titlesColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
});

export const customCssVars: CustomCssVarsFn = ({styleParams}) => {
  return {
    shouldDisplayCheckoutButton: styleParams.booleans.CART_ACTION_BUTTONS_CHECKOUT_VISIBILITY ? 'block' : 'none',
    shouldDisplayGoToCartButton: styleParams.booleans.CART_ACTION_BUTTONS_GO_TO_CART_VISIBILITY ? 'block' : 'none',
  };
};
